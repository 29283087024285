import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { getStaticProps } from '@/containers/withServerSideProps'

export default function Page404() {
  const router = useRouter()
  useEffect(() => {
    router.replace('/not-found')
  }, [router])
  return <div />
}

export { getStaticProps }
